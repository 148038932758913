module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/themes/default/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f2f83702fe2ff7d8a9ae36d4e8e2b5b3"},
    },{
      plugin: require('../plugins/gatsby-plugin-matomo-cust/gatsby-browser.js'),
      options: {"plugins":[],"matomoUrl":"https://statssh.prd.cms.fgov.be","dev":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
